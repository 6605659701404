<template>
<div>
  <h3>系统首页</h3>
<!-- 卡片风格首页 -->
  <el-card>
<!-- 上数据展示盒模型 -->
    <div class="box-top hidden-sm-and-down">
      <div class="box-top-01"><div class="box-body">
        <span>截至目前共：</span>
        <h2>￥{{price}}</h2>
        <p class="p-styles">金额</p>
      </div></div>
      <div class="box-top-02"><div class="box-body">
        <span>截至目前共：</span>
        <h2>{{user}}</h2>
        <p class="p-styles">用户</p>
      </div></div>
      <div class="box-top-03"><div class="box-body">
        <span>今日消费共：</span>
        <h2>{{order}}</h2>
        <p class="p-styles">订单</p>
      </div></div>
      <div class="box-top-04"><div class="box-body">
        <span>设备信息共：</span>
        <h2>{{device}}</h2>
        <p class="p-styles">设备</p>
      </div></div>

    </div>
<!-- 下echarts柱状图 -->
    <div class="hidden-sm-and-down">
      <div class="box-bottom1">
        <Echart1></Echart1>
      </div>
      <div class="box-bottom2">
        <Echart2></Echart2>
      </div>
    </div>

<!--  移动端ui  -->

    <div class="hidden-sm-and-up">
<!--总收益-->
      <div class="yield">
        <div class="yield-text">今日总收益</div>
        <div class="yield-money">0.00</div>
      </div>
<!--广告-->
      <div class="AD">
         <img src="../../icon/ad.png" class="AD">
      </div>

<!--功能盒子-->
      <div class="func">
        <el-row :gutter="8">
          <el-col :span="8"><div class="grid-content yellow">
            <div class="white">平台管理</div>
          </div></el-col>
          <el-col :span="8"><div class="grid-content blue">
            <div class="white">项目管理</div>
          </div></el-col>
          <el-col :span="8"><div class="grid-content green">
            <div class="white">角色管理</div>
          </div></el-col>
        </el-row>

      </div>

      <van-divider
          :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >

      </van-divider>

<!--业务盒子-->

        <div class="" @click="goBlow">
          <div>吹风机类</div>

            <el-row type="flex" class="row-bg" :gutter="8">
              <el-col :span="6"><div class="grid-content2 ">
                <img src="../../icon/dev.png" class="grid-content2-icon">
                <div class="grid-content2-text">设备管理</div>
              </div></el-col>
              <el-col :span="6"><div class="grid-content2 ">

              </div></el-col>
              <el-col :span="6"><div class="grid-content2 ">

              </div></el-col>
            </el-row>

        </div>



    </div>



  </el-card>
</div>
</template>

<script>



export default {
  name: "Welcome",
  components: {},

  data () {
    return {

      dataList:[],
      price:'781567',
      user:'7452',
      order:'47821',
      device:'4587',
    }
  },

  methods:{

   // 吹风机router
   async goBlow() {
    await this.$router.push({path:'/blowing'})
    },

  },
}
</script>

<style lang="less" scoped>
.box-top {
  width: 100%;
  height: 100%;
  text-align: center;
}
.box-top-01 {
  display:inline-block;
  width: 380px;
  height: 150px;
  background: #CC5656;
  margin: 20px 10px 20px 8px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15) !important;
}
.box-top-02 {
  display:inline-block;
  width: 380px;
  height: 150px;
  background: #0099FF;
  margin: 0 10px 16px 8px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15) !important;
}
.box-top-03 {
  display: inline-block;
  width: 380px;
  height: 150px;
  background: #70CC33;
  margin: 0 10px 16px 8px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15) !important;
}
.box-top-04 {
  display: inline-block;
  width: 380px;
  height: 150px;
  background: #FFCC00;
  margin: 0 10px 16px 8px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15) !important;
}

.red{
  background: #E65D5D;
}

.blue{
  background: #5071F3;
}

.green{
  background: #70D498;
}

.yellow{
  background: #E6AF5D;
}

.white{
  color: #FFFFFF;
}
.box-body {
  margin: 8px;
  text-align: center;
  color: #ffffff;
}
.p-styles {
  font-size: 20px;
}

.box-bottom1 {
  float: left;
  width: 50%;
  margin-bottom: 30px;
}

.box-bottom2 {
  float: left;
  width: 50%;

}

.yield {
  width: 100%;
  height: 100px;
  background: #4E8AE4;
  color: #FFFFFF;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;

}
.yield-text {
  font: normal 400 14px Serif;
  padding-top: 25px;
}
.yield-money {
  font: normal bolder 40px Serif;
}

.AD {
  width: 100%;
  height: 120px;

  border-radius: 5px;
  margin-bottom: 20px;
}

.grid-content {
  height: 60px;

  padding: 10px;
  border-radius: 10px;
}



.grid-content2 {
  height: 100px;

  position: relative;
}

.grid-content2-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.grid-content2-text {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
}

.func {
  padding-bottom: 20px;
}

</style>
